<template>
  <div id="app">
    <div class="left d-flex">
      <Banner />
      <Navbar class="navbar"/>
    </div>
    <div id="main-container" class="d-flex flex-column align-items-center">
      <Header />
      <h2 class="home-title">Home</h2>
      <p class="path">You are here: Home</p>
      <section v-show="showTopBlog" id="banner-wrapper">
        <div class="container">
          <HomeCard
          :blog_id="theTopBlog().blog_id"
          :blog_image="theTopBlog().blog_image1_dsp | emptyImage"
          :blog_title="theTopBlog().blog_title"
          :blog_online_date="theTopBlog().blog_online_dsp"
          :blog_content="theTopBlog().blog_content1"
          :isTopBlog="showTopBlog" />
        </div>
      </section>
      <section v-for="blogType in blogTypeList" :key="blogType.index" id="second-blog-wrapper">
        <sub class="blog-title">
          <p>{{ blogType }}</p>
          <div class="rectangle"></div>
        </sub>
        <div class="blog-wrapper">
          <HomeCard
          v-for="blog in showBlogs(blogType, blogList)"
          :key="blog.blog_id"
          :blog_id="blog.blog_id"
          :blog_title="blog.blog_title"
          :blog_online_date="blog.blog_online_dsp"
          :blog_image="blog.blog_image1_dsp | emptyImage"
          :blog_content="blog.blog_content1"
          />
        </div>
      </section>
      <div class="control-table">
        <ControlBtn :btnName="'繼續閱讀'" :route="'/blog'" />
        <BackToTop class="bTT" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import HomeCard from '@/components/HomeCard.vue'
import Banner from '@/components/Banner.vue'
import ControlBtn from '../../components/ControlBtn.vue'
import BackToTop from '../../components/BackToTop.vue'
import Navbar from '../../components/Navbar.vue'
import mainAPI from '../../apis/mainAPI'
import { Toast } from '../../utils/helpers'
import { emptyImgFilter } from '../../utils/mixins'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    HomeCard,
    Banner,
    ControlBtn,
    BackToTop,
    Navbar
  },
  mixins: [emptyImgFilter],
  data () {
    return {
      blogList: [],
      blogTypeList: [],
      getScreenWidth: window.innerWidth,
      totalPage: Number
    }
  },
  computed: {
    showTopBlog () {
      if (this.getScreenWidth > 768) {
        return true
      } else if (this.getScreenWidth <= 768) {
        return false
      } else {
        return 0
      }
    }
  },
  created () {
    this.fetchBlogList()
    this.fetchBlogTypeList()
  },
  methods: {
    // async fetchBlogList () {
    //   try {
    //     const { data } = await mainAPI.getBlogList()
    //     console.log(data)
    //     this.blogList.push(...data.msg.original.lists.data)
    //     this.blogList.sort(function (a, b) {
    //       return b.blog_id - a.blog_id
    //     })
    //     this.getOgImage()
    //   } catch (error) {
    //     Toast.fire({
    //       icon: 'warning',
    //       title: '無法取得BLOG資料，請稍後再試'
    //     })
    //   }
    // },
    async fetchBlogList () {
      try {
        let { data } = await mainAPI.getBlogList()
        this.totalPage = data.msg.original.lists.last_page
        for (let i = this.totalPage; i >= 1; i--) {
          let { data } = await mainAPI.getBlogList(i)
          this.blogList.push(...data.msg.original.lists.data)
        }
        this.blogList.sort(function (a, b) {
          return b.blog_id - a.blog_id
        })
      } catch (error) {
        Toast.fire({
          icon: 'warning',
          title: '無法取得blog資料，請稍後再試'
        })
      }
    },
    async fetchBlogTypeList () {
      try {
        const { data } = await mainAPI.getBlogTypeList()
        if (data.msg.original.lists.length === 0) {
          throw new Error(data.msg)
        }
        let arr = data.msg.original.lists.sort(function (a, b) {
          return b.blog_type_id - a.blog_type_id
        })
        arr.forEach(item => {
          this.blogTypeList.push(item.blog_type_title)
        })
      } catch (error) {
        Toast.fire({
          icon: 'warning',
          title: '無法取得BLOG分類資料，請稍後再試'
        })
      }
    },
    getOgImage () {
      this.ogImage = this.theTopBlog().blog_image1_dsp
    },
    theTopBlog () {
      if (this.blogList.find(blog => blog.blog_top_index_tag === '1')) {
        return this.blogList.find(blog => blog.blog_top_index_tag === '1')
      } else {
        return this.blogList.find(blog => blog.blog_show_index_tag === '1')
      }
    },
    showBlogs (blogType, arr) {
      // 當screen大於768時 如果有傳入 blogType則回傳分類之後的blog陣列
      // let arr1
      // if (this.getScreenWidth >= 769) {
      //   arr1 = blogType ? this.sortBlogType(blogType, arr).slice(0, 2) : this.blogList.slice(0, 2)
      // }
      // let blogs = []
      // arr1.forEach(item => {
      //   if (item.blog_show_index_tag === '1' && item.blog_top_index_tag === '0') {
      //     blogs.push(item)
      //   }
      // })
      // return blogs
      // 先選出含有show tag的blog 然後再分類 並選出前兩個
      let blogs = []
      arr.forEach(item => {
        if (item.blog_show_index_tag === '1' && item.blog_top_index_tag === '0') {
          blogs.push(item)
        }
      })
      if (this.getScreenWidth >= 768) {
        blogs = blogType ? this.sortBlogType(blogType, blogs).slice(0, 2) : this.blogList.slice(0, 2)
      }
      return blogs
    },
    sortBlogType (blogType, arr) {
      let newArr = []
      arr.forEach(item => {
        if (item.blog_type_title === blogType) {
          newArr.push(item)
        }
      })
      return newArr
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/_base.scss";
@import "../../assets/scss/reset.scss";
#main-container {
  margin-bottom: 63px;
}
.home-title {
  margin-top: 64px;
  margin-bottom: 16px;
  @include font-setting("Amiri", normal, 400, 46px, 25px, #000);
  text-align: center;
}
.path {
  margin-bottom: 27px;
  @include font-setting("Lora", normal, 400, 16px, 25px, #000);
  text-align: center;
}
#first-blog-wrapper, #second-blog-wrapper {
  margin-bottom: 63px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
#banner-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.blog-title {
  display: none;
}
.blog-wrapper {
  margin-top: 17px;
  display: grid;
  grid-gap: 40px;
}
.control-table {
  position: relative;
}
.bTT {
  position: absolute;
  right: -84px;
  bottom: 40px;
}
@media screen and (min-width: 768px) {
  #main-container {
    margin-bottom: 39px;
  }
  .home-title {
    margin-top: 32px;
  }
  .path {
    margin-bottom: 64px;
  }
  #second-blog-wrapper {
    margin-top: 40px;
    padding: 0 32px;
  }
  .blog-title {
    width: 704px;
    display: block;
    p {
      margin-bottom: 8px;
      @include font-setting('Noto Serif TC', normal, 400, 30px, 25px, #000);
    }
    .rectangle {
      width: 40px;
      height: 4px;
      background: linear-gradient(90deg, #A17560 12.5%, rgba(218, 190, 162, 0.26) 100%);
      border-radius: 6px;
    }
  }
  .blog-wrapper {
    margin-top: 30px;
    box-sizing: border-box;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 54px 12px;
  }
  .bTT {
    right: -261px;
    bottom: 67px;
  }
}
@media screen and (min-width: 1440px) {
  #app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
  }
  .left {
    position: fixed;
    z-index: 3;
  }
  .navbar {
    position: relative;
    width: 90px
  }
  #main-container {
    flex: 1;
    margin-bottom: 40px;
    padding-left: 400px;
  }
  .home-title {
    margin-top: 36px;
  }
  .blog-title {
    width: 800px;
  }
  .blog-wrapper {
    grid-gap: 32px 16px;
  }
  .bTT {
    right: -400px;
    bottom: 67px;
  }
}
</style>
