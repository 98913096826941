<template>
  <div :class="['card d-flex align-items-center', {showBlog: isTopBlog}]">
    <a :href="'/blog/' + blog_id">
      <div class="card-img">
        <img :src="blog_image | emptyImage" alt="" />
      </div>
    </a>
    <div class="card-info d-flex flex-column justify-content-start">
      <a :href="'/blog/' + blog_id">
        <p class="title">{{ blog_title }}</p>
      </a>
      <div class="online_date"><a href="/about">Nick Lin</a>・ {{ blog_online_date }}</div>
      <div class="content">{{ blog_content }}</div>
      <a :href="'/blog/' + blog_id" class="btn">繼續閱讀</a>
    </div>
  </div>
</template>

<script>
import { emptyImgFilter } from '../utils/mixins'
export default {
  name: 'Card',
  props: {
    isTopBlog: {
      type: Boolean,
      default: false
    },
    blog_id: String,
    blog_image: String,
    blog_title: String,
    blog_online_date: String,
    blog_content: String
  },
  mixins: [emptyImgFilter]
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_base.scss";
@import "../assets/scss/reset.scss";
.card {
  width: 327px;
  flex-direction: column;
  background-color: #fff;
  padding-bottom: 32px;
  a {
    width: 100%;
    height: 100%;
  }
  .card-img {
    width: 100%;
    // height: 324px;
    margin-bottom: 32px;
    img {
      object-fit: cover;
    }
  }
  .card-info {
    width: 100%;
    padding: 0 28px;
    a {
      width: auto;
      height: auto;
      margin-right: 0px;
    }
    .online_date {
      height: auto;
      margin-bottom: 40px;
      @include font-setting("Amiri", normal, 400, 16px, 16px, #93989C);
      a {
        @include font-setting("Amiri", normal, 400, 16px, 16px, #93989C);
      }
    }
    .title {
      margin-bottom: 12px;
      @include font-setting("Noto Serif TC", normal, 700, 30px, 36px, #A17560);
      @include text-rows(1);
    }
    .content {
      margin-bottom: 30px;
      @include font-setting("Noto Serif TC", normal, 400, 16px, 25px, #3D3D3B);
      @include text-rows(4);
    }
    .btn {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background-color: #2C2E36;
      @include font-setting("Noto Serif TC", normal, 600, 15px, 25px, #FFFFFF);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media screen and (min-width: 768px) {
  .card {
    width: 346px;
    .card-img {
      // height: 323.5px;
    }
    .card-info {
      .content {
        margin-bottom: 24px;
      }
      .btn {
        width: 187px;
      }
    }
  }
  .showBlog {
    width: 704px
  }
}
@media screen and (min-width: 1440px) {
  .card {
    width: 392px;
    padding-bottom: 48px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    .card-info {
      .online_date {
        margin-bottom: 24px;
      }
    }
  }
  .showBlog {
    width: 800px
  }
}
</style>
